<template>
  <div>
    <can-i :permissions="[$options.ROLE_UNIT_ADMINISTRATION]">
      <v-button
          icon="edit"
          @click="goToEditCardInfo"
      >
        {{ $t('page_unit.btn_edit_card_info') }}
      </v-button>
    </can-i>
    <v-empty-state v-if="isEmptyState"
                   v-loading="isLoading"
                   :subtitle="$t('page_unit.empty_states.unit_about.subtitle')"
                   :description="$t('page_unit.empty_states.unit_about.description')"
    />
    <div v-else class="editor-artifact-content ql-editor" v-html="unitAbout" />
  </div>
</template>

<script>
import 'quill/dist/quill.snow.css'


import VButton from '../../../components/ui/v-button/VButton'
import {fetchUnitCardInfo} from '../../unit-page/services/unit-sevice'
import CanI from '../../../auth/can-i/CanI'
import {ROLE_UNIT_ADMINISTRATION} from '../../../shared/constants/app-permissions'
import VEmptyState from '../../../components/ui/v-empty-state/VEmptyState'

export default {
  name: 'UnitTabAbout',
  components: {VEmptyState, CanI, VButton},
  ROLE_UNIT_ADMINISTRATION,
  data() {
    return {
      unitAbout: '',
      isLoading: false
    }
  },
  created() {
    this.fetchCardInfo()
  },
  computed: {
    isEmptyState() {
      return !Boolean(this.unitAbout.trim())
    }
  },
  methods: {
    goToEditCardInfo() {
      const { unitID } = this.$route.params
      this.$router.push({
        name: 'edit_unit_info',
        params: {
          unitID
        }
      })
    },
    fetchCardInfo() {
      const { unitID } = this.$route.params
      this.isLoading = true
      fetchUnitCardInfo(unitID)
          .then(res => {
            this.unitAbout = res
          })
          .finally(() => {
            this.isLoading = false
          })
    }
  }
}
</script>

